<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="container containerDash">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sidebar-dashboard></sidebar-dashboard>
              </div>

              <div class="col-lg-9">
                <div>
                  <div class="myaccount-dashboard">
                    <div
                      class=" pro-b-w-wrap"
                      style="margin-bottom: 25px"
                    >
                      <div class="row">
                        <div class="col-md-4">
                          <div class="profile-b-wrap">
                            <div class="">
                              <i
                                class="fa fa-hourglass-half one1"
                                aria-hidden="true"
                              ></i>
                              <h4>
                                <a href="/LiveAuctions">Active auctions</a>
                              </h4>
                              <router-link to="/LiveAuctions">
                                <h3>{{ dashBoard.active }}</h3></router-link
                              >
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-md-3">
                          <div class="profile-b-wrap">
                            <div class="">
                              <i
                                class="fa fa-spinner one2"
                                aria-hidden="true"
                              ></i>
                              <h4>
                                <a >Process winnings</a>
                              </h4>
                            <a>
                                <h3>
                                  {{ dashBoard.processWinning }}
                                </h3></a
                              >
                            </div>
                          </div>
                        </div> -->
                        <div class="col-md-4">
                          <div class="profile-b-wrap">
                            <div class="">
                              <i
                                class="fa fa-trophy one3"
                                aria-hidden="true"
                              ></i>
                              <h4><a href="/WonCustomer">Won customers</a></h4>
                              <router-link to="/WonCustomer">
                                <h3>{{ dashBoard.Won }}</h3></router-link
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="profile-b-wrap">
                            <div class="">
                              <i
                                class="fa fa-credit-card one4"
                                aria-hidden="true"
                              ></i>
                              <h4>
                                <a href="/PendingPayments">Pending Payments</a>
                              </h4>
                              <router-link to="/PendingPayments">
                                <h3>
                                  {{ dashBoard.paymentDues }}
                                </h3></router-link
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="profile-new-card-wrap noPrint">
                      <div class="row">
                        <div class="col-md-6">
                          <div
                            class="profile-new-card"
                            style="position: relative"
                          >
                            <div class="mem-details">
                              <img
                                src="../../../public/assets/images/account/userProfile.png"
                                class="img-fluid mem-details-img"
                              />
                              <div class="mem-det-wrap-content">
                                <h6>{{ currentuserinfo.sellerName }}</h6>
                                <!-- <span v-if="true">Package : Plus</span> -->
                                <span class="text-center">
                                  <a
                                    @click="showEditModal()"
                                    class="edit-prico"
                                  >
                                    <i class="fas fa-edit"></i
                                  ></a>
                                </span>
                              </div>
                            </div>
                            <div class="mem-c-de">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="mem-c-de-wrap">
                                    <i class="fa fa-phone"></i>
                                    <span>
                                      {{ currentuserinfo.sellerPhone }}</span
                                    >
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="mem-c-de-wrap">
                                    <i class="fa fa-envelope"></i>
                                    <span class="span-m-class">
                                      {{ currentuserinfo.sellerEmail }}</span
                                    >
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="">
                                    <div v-if="currentuserinfo.address">
                                      <span>
                                        {{ currentuserinfo.address }}</span
                                      >
                                    </div>
                                    <div v-else>
                                      <span>No address available</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="vehicleList.length !== 0">
                          <div class="profile-new-card">
                            <div class="profile-new-card-head">
                              <h4>Vehicle List</h4>
                              <a href="/MyVehicleListings">View More</a>
                            </div>
                            <div class="profile-new-card-content">
                              <table class="data pro-new-table">
                                <tr>
                                  <th>Vehicle Name</th>
                                  <th>Reg No</th>
                                  <th>Price</th>
                                </tr>
                                <tr
                                  v-for="(vehicle, index) in vehicleList"
                                  :key="index"
                                >
                                  <td>
                                    <span>{{ vehicle.vehicleName }}</span>
                                  </td>
                                  <td>
                                    <span>{{ vehicle.vehRegNo }}</span>
                                  </td>
                                  <td>
                                    <span
                                      >{{ vehicle.vehPrice | currency }}
                                    </span>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="auctionList.length !== 0">
                          <div class="profile-new-card">
                            <div class="profile-new-card-head">
                              <h4>Live Auction</h4>
                              <a href="/LiveAuctions">View More</a>
                            </div>
                            <div class="profile-new-card-content">
                              <table class="data pro-new-table">
                                <tr>
                                  <th>Auction Name</th>
                                  <th>Amount</th>
                                  <th>End Date</th>
                                </tr>
                                <tr
                                  v-for="(auction, index) in auctionList"
                                  :key="index"
                                >
                                  <td>
                                    <span>{{ auction.aucName }}</span>
                                  </td>
                                  <td>
                                    <span>{{
                                      auction.latestBidAmount | currency
                                    }}</span>
                                  </td>
                                  <td>
                                    <span
                                      >{{
                                        auction.auctionEndDate | formatDate
                                      }}
                                      IST</span
                                    >
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="wonAuctions.length !== 0">
                          <div class="profile-new-card">
                            <div class="profile-new-card-head">
                              <h4>Won</h4>
                              <a href="/WonCustomer">View More</a>
                            </div>
                            <div class="profile-new-card-content">
                              <table class="data pro-new-table">
                                <tr>
                                  <th>Auction Name</th>
                                  <th>Amount</th>
                                  <th>End Date</th>
                                </tr>
                                <tr
                                  v-for="(won, index) in wonAuctions"
                                  :key="index"
                                >
                                  <td>
                                    <span>{{ won.aucName }}</span>
                                  </td>
                                  <td>
                                    <span>{{
                                      won.aucMinWinAmount | currency
                                    }}</span>
                                  </td>
                                  <td>
                                    <span
                                      >{{
                                        won.auctionEndDate | formatDate
                                      }}
                                      IST</span
                                    >
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="paymentDues.length !== 0">
                          <div class="profile-new-card">
                            <div class="profile-new-card-head">
                              <h4>Payment dues</h4>
                              <a href="PendingPayments">View More</a>
                            </div>
                            <div class="profile-new-card-content">
                              <table class="data pro-new-table">
                                <tr>
                                  <th>Auction Name</th>
                                  <th>Amount</th>
                                  <th>End Date</th>
                                </tr>
                                <tr
                                  v-for="(due, index) in paymentDues"
                                  :key="index"
                                >
                                  <td>
                                    <span>{{ due.aucName }}</span>
                                  </td>
                                  <td>
                                    <span>{{ due.balance | currency }}</span>
                                  </td>
                                  <td>
                                    <span
                                      >{{
                                        due.auctionEndDate | formatDate
                                      }}
                                      IST</span
                                    >
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="lostAuctions.length !== 0">
                          <div class="profile-new-card">
                            <div class="profile-new-card-head">
                              <h4>Lost Auctions</h4>
                              <a href="Lost">View More</a>
                            </div>
                            <div class="profile-new-card-content">
                              <table class="data pro-new-table">
                                <tr>
                                  <th>Auction Name</th>
                                  <th>Amount</th>
                                  <th>End Date</th>
                                </tr>
                                <tr
                                  v-for="(lost, index) in lostAuctions"
                                  :key="index"
                                >
                                  <td>
                                    <span>{{ lost.aucName }}</span>
                                  </td>
                                  <td>
                                    <span>{{ lost.latestBid | currency }}</span>
                                  </td>
                                  <td>
                                    <span
                                      >{{
                                        lost.auctionEndDate | formatDate
                                      }}
                                      IST</span
                                    >
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- make it modal -->
                    <div class="modal" v-if="isEditModalActive">
                      <div class="modal-content modal-lg">
                        <div class="noPrint" style="margin-bottom: 18px">
                          <div class="l-section">
                            <h5 class="c-section__heading">Details</h5>
                            <b-button
                              style="float: right"
                              variant="outline-primary"
                              @click="closeEditModal()"
                            >
                              Close</b-button
                            >
                            <form class="formzzz">
                              <div v-if="!currentuserinfo"></div>
                              <div class="row" v-else>
                                <div class="col-md-6">
                                  <div>
                                    <label class="labelzzz">Name</label>
                                    <input
                                      v-validate="'required|min:3|max:20'"
                                      type="text"
                                      name="name"
                                      id="custName"
                                      v-model="currentuserinfo.sellerName"
                                      class="inputzzz"
                                      placeholder="User name"
                                    />
                                    <span
                                      v-show="errors.has('name')"
                                      class="help is-danger"
                                      >{{ errors.first("name") }}</span
                                    >
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div>
                                    <label class="labelzzz">Email</label>
                                    <input
                                      type="email"
                                      v-validate="'required|email|max:50'"
                                      name="email"
                                      v-model="currentuserinfo.sellerEmail"
                                      class="inputzzz"
                                      placeholder="Email"
                                    />
                                    <span
                                      v-show="errors.has('email')"
                                      class="help is-danger"
                                      >{{ errors.first("email") }}</span
                                    >
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div>
                                    <label class="labelzzz">Phone</label>
                                    <VuePhoneNumberInput
                                      style="padding-top: 7px"
                                      v-model="numberMobile"
                                      size="lg"
                                      :default-country-code="countryCode"
                                      :required="true"
                                      color="red"
                                      :noExample="true"
                                      @update="resultsExample = $event"
                                    />
                                    <div
                                      v-if="submitted && phoneMessageError"
                                      class="alert-danger"
                                    >
                                      {{ phoneMessageError }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div>
                                    <label class="labelzzz">Address</label>
                                    <input
                                      type="text"
                                      v-validate="'required|min:10'"
                                      name="address"
                                      v-model="currentuserinfo.address"
                                      class="inputzzz"
                                      placeholder="Address"
                                    />
                                    <span
                                      v-show="errors.has('address')"
                                      class="help is-danger"
                                      >{{ errors.first("address") }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                              <div style="text-align: right">
                                <button
                                  style="
                                    width: 20%;
                                    border-radius: 4px;
                                    font-weight: 500;
                                  "
                                  type="button"
                                  @click="updatesellerprofile"
                                  class="buttonzzz"
                                >
                                  UPDATE
                                </button>
                              </div>
                              <p>{{ message }}</p>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <b-modal
                id="modalRejected"
                ref="modalRejected"
                @show="resetModal"
                @hidden="resetModal"
                hide-footer
                v-if="currentUser"
              >
                <form ref="form">
                  <div>
                    <p for="bidamount" class="bidamountzz">Reason</p>

                    <b-form-textarea
                      id="textarea"
                      v-model="rjReason"
                      placeholder="Enter something..."
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </div>

                  <b-button
                    type="button"
                    class="btn subBtnzz"
                    @click="bidentry(list, rjReason)"
                    style="width: 100%; margin-top: 10px"
                  >
                    SUBMIT
                  </b-button>
                  <div class="form-group" v-if="message == 'Success'">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="message">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                </form>
              </b-modal>
              <b-modal
                id="modalSold"
                ref="modalSold"
                @show="resetModal"
                @hidden="resetModal"
                hide-footer
              >
                <p>Saved Successfully</p>
              </b-modal>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import CountryCodeList from "../../../public/AddOn/CountryCodeList";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import userService from "@/services/user.service";
import VehDetails from "@/models/vehDetails";
import Config from "@/config";
import moment from "moment";
export default {
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      currentuserinfo: "",
      message: "",
      imagesmessage: "",
      disabled: 0,
      vehiclenfo: new VehDetails("", "", "", "", "", "", "", "", "", ""),
      sellerNamemessage: "",
      sellerEmailmessage: "",
      sellerPhonemessage: "",
      customerList: "",
      rjReason: "",
      initialLoading: true,
      countryCode: "",
      numberMobile: null,
      phoneMessageError: "",
      resultsExample: null,
      submitted: false,
      dashBoard: [],
      vehicleList: [],
      auctionList: [],
      wonAuctions: [],
      paymentDues: [],
      lostAuctions: [],
      isEditModalActive: false,
    };
  },

  created() {
    if (this.$store.state.auth.user) {
      if (localStorage.getItem("reloaded")) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem("reloaded");
      } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem("reloaded", "1");
        location.reload();
        //this.st = this.$store.state.auth.user[0].customerId
      }
    }
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].sellerId == "" ||
        !this.$store.state.auth.user[0].sellerId == " "
      ) {
        this.$router.push("/vendor").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
  },
  mounted() {
    if (this.$store.state.auth.user) {
      this.st = this.$store.state.auth.user[0];
      this.getsellerbyId();
      this.getDashboardProfileFe(this.st.sellerId, "seller");
      // this.getWonCustomersAgainstSellerAuctions(this.st.sellerId);
    } else {
      this.$router.push("/").catch(() => {
        "myaccount";
      });
    }
  },
  updated() {
    if (this.resultsExample != null) {
      if (this.resultsExample.isValid == true && this.phoneMessageError != "") {
        this.phoneMessageError = "";
        // console.log(this.resultsExample.isValid);
      }
    }
  },
  methods: {
    showEditModal() {
      this.isEditModalActive = !this.isEditModalActive;
    },
    closeEditModal() {
      this.isEditModalActive = false;
    },
    getDashboardProfileFe(custid, type) {
      // getCustomerPackages
      let id = custid;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService.getDashboardProfileFe(id, type).then((response) => {
          // console.log(response.data);
          this.dashBoard = response.data.Data.result1[0];
          this.vehicleList = response.data.Data.result2;
          this.auctionList = response.data.Data.result3;
          this.wonAuctions = response.data.Data.result4;
          this.paymentDues = response.data.Data.result5;
          this.lostAuctions = response.data.Data.result6;
        });
      }
    },
    resetModal() {
      this.message = "";
      this.bid = "";
      this.message = null;
      this.bid = null;
    },
    saveAsSold(list) {
      userService.saveAsSold(list).then((response) => {
        this.message = response.data.Message;
        this.profileinfo();
      });
    },
    saveAsRejected(list) {
      userService
        .saveAsRejected(list)
        .then((response) => {
          this.message = response.data.Message;
          this.profileinfo();
        })
        .catch((err) => {
          this.message = err.response.data.Message;
        });
    },
    getWonCustomersAgainstSellerAuctions(sellerId) {
      // console.log(sellerId);
      if (sellerId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getPaymentDueAgainstSellerAuctions(sellerId)
          .then((response) => {
            this.customerList = response.data.Data;
            this.initialLoading = false;
            // console.log(this.customerList);
          })
          .catch((e) => {
            console.log(e);
            this.initialLoading = false;
          });
      }
    },
    getsellerbyId() {
      let id = this.st.sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getsellerbyId(id)
          .then((response) => {
            this.currentuserinfo = response.data.Data[0];
            let lodsad = this.currentuserinfo.sellerPhone.split("-");
            // console.log(lodsad[0]);
            this.numberMobile = lodsad[1];

            for (let index = 0; index < CountryCodeList.length; index++) {
              const element = CountryCodeList[index];

              if (element.CountryCallingCode == lodsad[0]) {
                this.countryCode = element.countryCode;
                //  console.log(element.countryCode);
              }
            }
          })
          .catch((e) => {
            console.log(e);
            // localStorage.clear();
          });
      }
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/").catch(() => {
        "getuserbyid";
      });
    },
    //////////

    updatesellerprofile() {
      this.submitted = true;
      this.currentuserinfo.sellerPhone =
        "+" +
        this.resultsExample.countryCallingCode +
        "-" +
        this.resultsExample.nationalNumber;
      // console.log(this.currentuserinfo);
      this.$validator.validateAll().then((value) => {
        if (value) {
          let id = this.st.sellerId;
          //console.log(this.currentuserinfo);
          if (id == "undefind") {
            console.log("undefind");
          } else {
            if (this.resultsExample.isValid == true) {
              this.phoneMessageError = "";
              userService
                .updatesellerinfo(id, this.currentuserinfo)
                .then((response) => {
                  if (response.data.Message == "Success") {
                    this.makeToast("success", "Profile updated successfully");

                    setTimeout(() => {
                      this.profileinfo();
                    }, 2000);
                  }
                })
                .catch((err) => {
                  let resMessage =
                    (err.response && err.response.data.Message) ||
                    err.message ||
                    err.toString();
                  this.makeToast("danger", resMessage);
                });
            } else {
              this.phoneMessageError = "Please check the phone number";
            }
          }
          return;
        }
      });
    },

    pagerefresh() {
      if (localStorage.getItem("reloaded")) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem("reloaded");
      } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem("reloaded", "1");
        location.reload();
      }
    },
    profileinfo() {
      location.reload();
    },
    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }
      if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }
      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: Config.toastDelay,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("D-MMM-YYYY hh:mm a");
      }
    },

    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
}
.pdtSliderhome {
  background-color: white;
  border-radius: 5px;
  margin: 10px 8px;
  padding: 5px;
}

.btn-upload {
  padding: 10px 20px;
  margin-left: 10px;
}
.upload-input-group {
  margin-bottom: 10px;
}

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  height: 45px;
}
.imgUploadVehicle {
  margin-top: 20px;
  width: 100%;
}
th label {
  margin-bottom: 0px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}
table tr {
  background-color: #fff;
  border: 1px solid #e1e5e9;
  padding: 0.35em;
  border-radius: 3px;
}
table thead tr:first-child {
  border: 1px solid #0094da;
}
table th,
table td {
  padding: 8px;
  text-align: left;
  color: #202020;
  font-size: 14px;
}

table td:nth-child(4) {
  font-size: 18px;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: #0094da;
  color: #fff;
}

table tbody tr td .btn-invoice {
  background: #0094da;
  color: #fff;
  font-size: 13px;
  padding: 10px 20px;
  border: 0;
  width: 100%;
  border-radius: 5px;
}

tbody tr:nth-child(even) {
  background-color: #eee;
}

tbody tr:nth-child(odd) {
  background-color: #fff;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }
  table caption {
    font-size: 1.3em;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }
  table tr {
    border-bottom: 3px solid #e1e5e9;
    display: block;
    margin-bottom: 0.625em;
  }
  table th,
  table td {
    padding: 0.625em;
  }
  table td {
    border-bottom: 1px solid #e1e5e9;
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #9da9b9;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    color: #656971;
  }
  table td:last-child {
    border-bottom: 0;
  }
  table td:nth-child(4) {
    font-size: 0.8em;
  }
}
</style>
